'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaData.factory:Tasks

 # @description

###
angular
  .module 'lpaData'
  .factory 'Tasks', [
      'Restangular'
      (Restangular) ->
        Restangular.service('lpa/tasks')
    ]
